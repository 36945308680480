<template>
  <div>
    <v-row class="mx-0 mb-12">
      <v-col :cols="6" v-for="(item, index) in productList" :key="index">
        <div class="Card my-0" @click="goDetail(item)">
          <div style="position: relative">
            <v-row class="mx-0 px-5">
              <v-img :src="item.image" contain width="200" height="150" />
              <v-icon class="iconPlus" large @click.stop="addItem(item)">
                add_circle_outline
              </v-icon>
            </v-row>
          </div>
          <v-row class="mx-0 detail pl-2">
            <v-col :cols="12" class="pa-0 mt-1">
              <span class="titleName">{{ item.name }}</span>
            </v-col>
            <v-col :cols="12" class="pa-0">
              <span class="description">{{ item.description }}</span>
            </v-col>
            <span class="price">THB {{ priceBook(item) }}</span>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ProductCard',
  props: {
    productList: Array,
  },
  computed: {
    ...mapState(['storeType']),
    priceBook() {
      return item => {
        const priceBook = item.price_book?.find(item => {
          return item.pricebook_type === this.storeType
        })
        return priceBook?.price || item.list_price.toLocaleString()
      }
    },
  },
  methods: {
    async goDetail(item) {
      const self = this
      self.$router.push({ name: 'ProductDetail', params: { item } })
    },
    async addItem(v) {
      const self = this
      try {
        const res = await self.$axios({
          method: 'POST',
          url: '/api/cart/add',
          data: {
            social_id: self.$store.state.tokenId,
            product_id: v.sku,
            store_id: self.$store.state.storeId,
            social_channel: 'line',
          },
        })
        if (res.data.status === 'success') {
          const cart = await self.$store.dispatch('getCart')
          if (!self.$store.state.storeId) {
            self.$store.commit('setStoreId', cart.merchant_detail[0].store_id)
            self.$store.commit('setStoreName', cart.merchant_detail[0].name_store)
            self.$store.commit('setStoreType', cart.merchant_detail[0]?.shop_type)
          }
          self.$store.commit('setCart', cart)
        } else {
          self.$modal.show({
            title: 'ไม่สามารถเพิ่มสินค้าลงในตะกร้าได้',
            isOne: true,
          })
        }
      } catch (e) {
        console.log(e)
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.iconPlus {
  position: absolute;
  right: 0;
}
.detail {
  background-color: #efefef;
  min-height: 100px;
}
</style>
