<template>
  <v-content>
    <ProductCard :productList="productList" />
    <div :class="checkAmount">
      <div class="fixButtom">
        <v-col :cols="12" class="cartStatus pa-2" v-if="cart.products">
          <v-icon :size="25">$vuetify.icons.cart </v-icon>
          <span>{{
            `คุณเลือก ${cart.products.length} สินค้าจำนวน ${amountProduct} ชิ้นมูลค่า`
          }}</span>
          <span class="price"> THB {{ cart.grand_total.toLocaleString() }}</span>
        </v-col>
        <ButtonGroup
          :funcLeft="cancleButton"
          :funcRight="confirmButton"
          titleLeft="ยกเลิก"
          titleRight="ดูตะกร้าสินค้า"
        ></ButtonGroup>
      </div>
    </div>
  </v-content>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup'
import ProductCard from '../components/ProductCard'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ProductList',
  components: {
    ProductCard,
    ButtonGroup,
  },
  data() {
    return {
      productList: [],
    }
  },
  methods: {
    ...mapActions(['getCart']),
    cancleButton() {
      const self = this
      self.$router.go(-2)
    },
    confirmButton() {
      const self = this
      self.$router.push('/cart')
    },
    async getProductList() {
      const self = this
      try {
        const res = await self.$axios({
          method: 'GET',
          url: '/api/products',
          params: self.$route.query,
        })
        if (res.data.status === 'failed') {
          self.$modal.show({
            title: `ERROR: ${res.data.message}`,
            isOne: true,
          })
        }
        self.productList = res.data
      } catch (e) {
        console.log(e)
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
  },
  computed: {
    ...mapState(['cart']),
    amountProduct() {
      if (this.cart) {
        return this.cart.products.map(item => item.qty).reduce((acc, value) => acc + value, 0)
      }
      return 0
    },
    checkAmount() {
      return this.cart.products !== 0 && 'mb-14'
    },
  },
  async created() {
    const self = this
    self.getProductList()
    try {
      const cart = await self.getCart()
      if (!self.$store.state.storeId) {
        self.$store.commit('setStoreId', cart.merchant_detail[0].store_id)
        self.$store.commit('setStoreName', cart.merchant_detail[0].name_store)
        self.$store.commit('setStoreType', cart.merchant_detail[0]?.shop_type)
      }
      self.$store.commit('setCart', cart)
    } catch (e) {
      console.log(e)
      self.$modal.show({
        title: `ERROR: ${e}`,
        isOne: true,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.cartStatus {
  display: flex;
  justify-content: space-around;
  background-color: #efefef;
}
</style>
